import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="California Mortgage Loan Calculator | Nikkael Home Loans"
        description="Being prepared is one of the best things you can do when getting a mortgage. Calculate your payments with our California Mortgage Loan Calculator here!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="my-20 md:mb-32 md:px-5">
        <div className="container">
          <header className="mx-auto mb-10 max-w-[720px] md:mb-14 md:text-center">
            <h1>California Mortgage Loan Calculator</h1>
            <p>
              Being prepared is one of the best things you can do when getting a
              mortgage. Feel free to use our home loan calculator to see what
              your monthly payments could be based on your figures.
            </p>
          </header>
          <section className="py-2.5 md:py-4">
            <div className="container">
              <div className="grid gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
                <div className="items-start md:flex md:space-x-8">
                  <div>
                    {" "}
                    <header className="mb-12 md:mb-16">
                      <h2>If You’re Refinancing</h2>
                      <p className="text-xl">
                        Follow these simple steps to calculate a refinance
                        mortgage using our California loan calculator:
                      </p>
                    </header>
                  </div>
                </div>
                <div className="items-start md:flex md:space-x-8">
                  <div>
                    <ul className="styled-list mb-6 ">
                      <li>
                        <p className="mb-0 inline-block text-xl">
                          Input your home’s current market value in the box
                          labeled{" "}
                          <p className="inline-block font-bold text-primary-900">
                            Home Price
                          </p>
                        </p>
                      </li>
                      <li className="before:text-xl">
                        <p className=" inline-block text-xl">
                          Input the current equity you have in your home in the
                          box labeled{" "}
                          <span className="inline-block pr-[4px] font-bold text-primary-900">
                            {" "}
                            Down Payment
                          </span>
                          (to determine how much equity you have, subtract your
                          current unpaid principal balance by the home’s current
                          market value)
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <iframe
            src="https://mortgage-tools.flywheelsites.com/home-mortgage-calculator/"
            width="100%"
            height="100%"
            title="Mortgage Calculator"
            frameBorder="0"
            className="h-[1100px] md:h-[700px]"
          ></iframe>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-global.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
